import React from 'react';
import { PageProps, graphql } from 'gatsby';

import Layout, { ILayout } from 'modules/layout';
import Meta from 'modules/meta';
import Template from './Template';

export const query = graphql`
  query Careers {
    contentfulSiteConfig {
      ...siteConfig
    }
    contentfulCareers {
      ...heroCareers
      ...metaCareers
      heading
      description {
        description
      }
    }
    allContentfulCareer {
      ...allCareers
    }
  }
`;

const Page: React.FunctionComponent<PageProps<Queries.CareersQuery>> &
  ILayout = ({ data }) => {
  return <Template {...data} />;
};

Page.Layout = Layout;
export default Page;

export const Head: React.FunctionComponent<PageProps<Queries.CareersQuery>> = ({
  data,
  location,
}) => {
  return (
    <Meta
      defaultMeta={data.contentfulSiteConfig?.defaultMeta}
      meta={data.contentfulCareers?.meta}
      pageTitle="Careers"
      pathname={location.pathname}
    />
  );
};
